<template>
	<div class="layout-padding">
		<div class="layout-padding-auto layout-padding-view">
			<slot></slot>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
	name: 'mainContainer',
	setup() { },
	props: {
		block: {
			type: Boolean,
			default: false,
		},
	},
});
</script>