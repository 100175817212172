import axios from 'axios';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Session } from '/@/utils/storage';
import { storeToRefs } from 'pinia';
import { useUserInfo } from '/@/stores/userInfo';
let isLoading = false;
// 添加请求拦截器
axios.interceptors.request.use(
	(req) => {
		const isToken = (req.headers || {}).isToken === false;
		// 在发送请求之前做些什么 token
		const token = Session.get('token');
		if (token && !isToken) {
			(<any>req.headers)['satoken'] = token;
		}
		const stores = useUserInfo();
		const { userInfos } = storeToRefs(stores);
		// if (
		// 	(req.method === 'post' || req.method === 'put' || req.method === 'delete') &&
		// 	userInfos.value.id === '1' &&
		// 	req.url !== '/auth/token/logout'
		// ) {
		// 	ElMessage({
		// 		message: '演示账号，不能操作。',
		// 		type: 'error',
		// 	});
		// 	return Promise.reject(new Error('演示账号，不能操作。'));
		// }
		return req;
	},
	(error) => {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);
// 添加响应拦截器
axios.interceptors.response.use(
	(res: any) => {
		// 对响应数据做点什么
		const status = Number(res.status) || 200;
		const message = res.data.msg || '未知错误';
		if (res.data.code === 401) {
			ElMessageBox.confirm('令牌状态已过期，请点击重新登录', '系统提示', {
				confirmButtonText: '重新登录',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					Session.clear(); // 清除浏览器全部临时缓存
					window.location.href = '/'; // 去登录页
				})
				.catch(() => {});
			return;
		}

		if (status !== 200 || res.data.code === -1) {
			ElMessage.error(message);
			return Promise.reject(new Error(message));
		}

		return res;
	},
	(error) => {
		// 对响应错误做点什么
		// 处理 503 网络异常
		if (error.response.status === 503) {
			ElMessage.error(error.response.data.msg);
		} else if (error.response.status === 500) {
			ElMessage.error(error.response.data.msg);
			console.log('500=>>>>>>>' + error);
		} else if (error.message == 'Network Error') {
			ElMessage.error('网络连接错误');
		} else {
			if (error.response.data) ElMessage.error(error.response.statusText);
			else ElMessage.error('接口路径找不到');
		}
		return Promise.reject(new Error(error));
	}
);
// 导出 axios 实例
export default axios;
