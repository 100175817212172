<template>
	<div ref="printRef">
		<el-tooltip effect="dark" content="搜索" placement="top">
			<span class="box-item">
				<el-button circle @click="toSearch" v-if="searchBtn">
					<el-icon style="margin-right: 0px"> <ele-Search /> </el-icon
				></el-button>
			</span>
		</el-tooltip>
		<el-tooltip effect="dark" content="刷新" placement="top">
			<span class="box-item">
				<el-button circle @click="toRefresh" v-if="refreshBtn">
					<el-icon style="margin-right: 0px"> <ele-Refresh /> </el-icon
				></el-button>
			</span>
		</el-tooltip>
		<el-tooltip effect="dark" content="打印" placement="top">
			<span class="box-item">
				<el-button circle @click="onPrintJs" v-if="printBtn"> <SvgIcon style="margin-right: 0px" name="iconfont icon-dayin" /></el-button>
			</span>
		</el-tooltip>
		<el-tooltip effect="dark" content="打印" placement="top">
			<span class="box-item">
				<el-button circle @click="onExcel" v-if="excelBtn"> <SvgIcon style="margin-right: 0px" name="iconfont icon-yunxiazai_o" /></el-button>
			</span>
		</el-tooltip>
	</div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import printJs from 'print-js';
import table2excel from 'js-table2excel';
export default defineComponent({
	props: {
		searchBtn: {
			type: Boolean,
			default: false,
		},
		refreshBtn: {
			type: Boolean,
			default: false,
		},
		printBtn: {
			type: Boolean,
			default: false,
		},
		printRef: {
			type: Object,
			default: null,
		},
		excelBtn: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, ctx) {
		// 搜索
		const toSearch = () => {
			ctx.emit('search');
		};
		// 刷新
		const toRefresh = () => {
			ctx.emit('refresh');
		};
		// 打印点击
		const onPrintJs = () => {
			// ctx.emit('printJs');
			printJs({
				printable: props.printRef,
				type: 'html',
				css: ['//at.alicdn.com/t/c/font_2298093_rnp72ifj3ba.css', '//unpkg.com/element-plus/dist/index.css'],
				scanStyles: false,
				style: `@media print{.mb15{margin-bottom:15px;}.el-button--small i.iconfont{font-size: 12px !important;margin-right: 5px;}}`,
			});
		};
		const onExcel = () => {
			// table2excel(props.header, state.selectlist, `${themeConfig.value.globalTitle} ${new Date().toLocaleString()}`);
		};
		return {
			toSearch,
			toRefresh,
			onPrintJs,
			onExcel,
		};
	},
});
</script>
<style lang="scss" scoped>
.box-item {
	margin: 5px;
}
</style>
