import request from '/@/utils/request';

export const getMenu = () =>
	request({
		url: '/upms/menu',
		method: 'get',
	});

export const getUserMenu = () =>
	request({
		url: '/upms/menu/user/tree',
		method: 'get',
	});

export const getList = () =>
	request({
		url: '/upms/menu/tree',
		method: 'get',
	});

export const getById = (id: string) =>
	request({
		url: '/upms/menu/' + id,
		method: 'get',
	});

export const addObj = (data: any) =>
	request({
		url: '/upms/menu',
		method: 'post',
		data,
	});

export const editObj = (data: any) =>
	request({
		url: '/upms/menu',
		method: 'put',
		data,
	});

export const delObj = (id: string) =>
	request({
		url: '/upms/menu/' + id,
		method: 'delete',
	});
/**
 * 添加菜单权限 （增删改查）
 */
export const addAuths = (data: any) =>
	request({
		url: '/upms/menu/addauths',
		method: 'post',
		data,
	});
/**
 * 查询角色的菜单集合
 */
export const getMenusByRole = (query: any) =>
	request({
		url: '/upms/menu/role/tree',
		method: 'get',
		params: query,
	});
