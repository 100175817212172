import { createApp } from 'vue';
import pinia from '/@/stores/index';
import App from '/@/App.vue';
import router from '/@/router';
import { directive } from '/@/directive/index';
import { i18n } from '/@/i18n/index';
import other from '/@/utils/other';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import '/@/theme/index.scss';
import VueGridLayout from 'vue-grid-layout';
import mainContainer from '/@/components/mainContainer/index.vue';
import rightToolbar from '/@/components/rightToolbar/index.vue';
import pagination from '/@/components/pagination/index.vue';
const app = createApp(App);
// 注册全局容器
app.component('mainContainer', mainContainer);
app.component('rightToolbar', rightToolbar);
app.component('pagination', pagination);
directive(app);
other.elSvg(app);

app.use(pinia).use(router).use(ElementPlus).use(i18n).use(VueGridLayout).mount('#app');
