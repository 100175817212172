import { defineStore } from 'pinia';
import Cookies from 'js-cookie';
import { UserInfosStates } from './interface';
import { Session } from '/@/utils/storage';
import { getUserInfo } from '/@/api/login/index';

/**
 * 用户信息
 * @methods setUserInfos 设置用户信息
 */
export const useUserInfo = defineStore('userInfo', {
	state: (): UserInfosStates => ({
		userInfos: {
			id: '',
			username: '',
			nikeName: '',
			avatar: '',
			time: 0,
			roles: [],
			permissions: [],
		},
	}),
	actions: {
		async setUserInfos() {
			// 存储用户信息到浏览器缓存
			if (Session.get('userInfo')) {
				this.userInfos = Session.get('userInfo');
			} else {
				const userInfos = <UserInfos>await this.getApiUserInfo();
				this.userInfos = userInfos;
			}
		},
		// 模拟接口数据
		// https://gitee.com/lyt-top/vue-next-admin/issues/I5F1HP
		async getApiUserInfo() {
			return new Promise((resolve, reject) => {
				getUserInfo()
					.then((res) => {
						const data = res.data.data || {};
						Session.set('userInfo', data);
						resolve(data);
					})
					.catch((e) => {
						reject();
					});
			});
		},
	},
});
