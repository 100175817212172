import CryptoJS from 'crypto-js';

export const encrypt = (word: string, keyStr: string) => {
	keyStr = keyStr ? keyStr : '123123123'; //判断是否存在ksy，不存在就用定义好的key
	var key = CryptoJS.enc.Utf8.parse(keyStr);
	var srcs = CryptoJS.enc.Utf8.parse(word);
	var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
	return encrypted.toString();
};

export const decrypt = (word: string, keyStr: string) => {
	keyStr = keyStr ? keyStr : '123123123';
	var key = CryptoJS.enc.Utf8.parse(keyStr);
	var decrypt = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
	return CryptoJS.enc.Utf8.stringify(decrypt).toString();
};
