<template>
	<el-pagination
		background
		layout="total, sizes, prev, pager, next, jumper"
		:total="total"
		v-model:current-page="currentPage"
		v-model:page-size="pageSize"
	></el-pagination>
</template>
<script lang="ts">
import { defineComponent, watch, ref } from 'vue';

export default defineComponent({
	props: {
		total: {
			type: Number,
			default: 0,
		},
		currentPage: {
			type: Number,
			default: 1,
		},
		pageSize: {
			type: Number,
			default: 10,
		},
	},
	setup(props, { emit }) {
		const total = ref(props.total);
		const currentPage = ref(props.currentPage);
		const pageSize = ref(props.pageSize);
		watch(
			() => props.total,
			() => {
				total.value = props.total;
			}
		);
		watch(
			() => currentPage.value,
			() => {
				emit('update:currentPage', currentPage.value);
				emit('changePage');
			}
		);
		watch(
			() => pageSize.value,
			() => {
				emit('update:pageSize', pageSize.value);
				emit('changePage');
			}
		);
		return {
			total,
			currentPage,
			pageSize,
		};
	},
});
</script>
