import request from '/@/utils/request';
import { encrypt } from '/@/utils/aes';

export const loginByUserName = (username: string, password: string, code: string, randomStr: string) => {
	// 密码aes加密
	password = encrypt(password, 'VyBcekSelErhMYN4');
	return request({
		url: '/auth/token/login',
		headers: {
			isToken: false,
		},
		method: 'post',
		params: { username, password, randomStr, code },
	});
};
export const loginByPhone = (phone: string, code: string) => {
	return request({
		url: '/auth/token/phone/login',
		headers: {
			isToken: false,
		},
		method: 'post',
		params: { phone, code },
	});
};

export const getUserInfo = () => {
	return request({
		url: '/upms/user/info',
		method: 'get',
	});
};

export const refreshToken = () => {
	return request({
		url: '/user/refresh',
		method: 'post',
	});
};

export const logout = () => {
	return request({
		url: '/auth/token/logout',
		method: 'delete',
	});
};

export const getSmsCode = (phone: string) => {
	return request({
		url: '/code/phone',
		method: 'get',
		params: { phone },
	});
};
